import { mdiHomeOutline, mdiMapMarkerOutline } from '@mdi/js'

/* eslint-disable */
// prettier-ignore
export default [
{ header: 'Pages' },

{ title: 'Home', icon: mdiHomeOutline, to: 'home' },
{ title: '區域設定', icon: mdiMapMarkerOutline, to: 'area' },
]
