import Vue from 'vue'
import VueRouter from 'vue-router'
import authGuard from '../auth/authGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'pos'
  },
  {
    path: '/pos',
    name: 'pos',
    component: () => import('@/views/Pos.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/area',
    name: 'area',
    component: () => import('@/views/Area.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '/daily',
    name: 'daily',
    component: () => import('@/views/KMdaily.vue'),
    meta: {
      layout: 'content'
    }
  },
  {
    path: '*',
    redirect: 'error-404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

// 強制登入
router.beforeEach((to, from, next) => authGuard(to, from, next))

export default router
