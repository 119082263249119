import { mdiCurrencyUsd, mdiFileDocumentOutline } from '@mdi/js'

export default [
  {
    title: 'POS系統',
    icon: mdiCurrencyUsd,
    to: 'pos',
  },
  {
    title: '開單資料列表',
    icon: mdiFileDocumentOutline,
    to: 'daily',
  },
]
