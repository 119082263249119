import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import authConfig from '../auth_config.json'
import App from './App.vue'
import { Auth0Plugin } from './auth'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

const NODE_ENV = process.env.NODE_ENV ? process.env.NODE_ENV : 'development'
const { domain } = authConfig[NODE_ENV]
const { clientId } = authConfig[NODE_ENV]

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    )
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
